import { isEmpty } from 'ramda';

const formattedOrderStatuses = {
  CLOSED: 'closed',
  WAITING_FOR_PRICE: 'waiting',
  WAITING_FOR_CONFIRMATION: 'waiting',
  WAITING_FOR_OPERATOR_CONFIRMATION: 'new',
  PAYMENT_IN_PROGRESS: 'in progress',
  CONFIRM: 'confirm',
  CONFIRMATION: 'confirm',
};

export const formatHistoryOrderStatusToView = order => {
  if (isEmpty(order)) return '-';
  const { status } = order;
  const formattedStatus = (status && status.toLowerCase()) || status;
  const orderStatusToView = formattedOrderStatuses[status] || formattedStatus;
  return orderStatusToView;
};
