<template>
  <div class="min-h-[670px]">
    <div class="ProvidersHistoryTable_filters">
      <Select
        class="ProvidersHistoryTable_filter"
        :items="filterTypes"
        :value="providersHistoryFilters.tr_type"
        name="tr_type"
        @change="onFilterChange"
        label="type"
        placeholder="type"
      />
      <div
        class="ProvidersHistoryTable_filter ProvidersHistoryTable_filter--relative"
        v-click-outside="handleOutsidePickerClick"
      >
        <TextFieldInput
          customStylesTextFieldInput="date-picker-input"
          :value="computedDateInputValue"
          name="date"
          placeholder="Date"
          label="Date"
          disabled
          hideDetails
          dropdownArrow
          @click="handleActivateDatePicker"
        />
        <DatePicker
          v-if="datePickerActive"
          :datesValue="computeDatePickerValue"
          :selectVariants="filterDateTypes"
          :selectValue="providersHistoryDateFilter.date_type"
          dateRange
          class="absolute z-5"
          @updateSelect="handleUpdateDateFilerSelect"
          @updateDate="handleUpdateDateValue"
          @submit="handleApplyDateFilter"
          @clear="handleClearDateFilter"
        />
      </div>
      <CurrencyFilter
        currencyFilterStyles="cursor-pointer flex items-center justify-between h-[46px] border border-[#E8ECF3] dark:border-none bg-white dark:bg-white/[0.04] items-center rounded-[4px] px-[12px]"
        class="ProvidersHistoryTable_filter"
        :defaulCurrency="providersHistoryFilters.currency"
        :defaulCurrencyList="currenciesFilter"
        @change="onFilterChange"
      />
      <StatusFilter
        styleStatusFilter="cursor-pointer flex items-center justify-between h-[46px] border border-[#E8ECF3] dark:border-none bg-white dark:bg-white/[0.04] items-center rounded-[4px] px-[12px]"
        class="ProvidersHistoryTable_filter"
        :defaulStatus="providersHistoryFilters.tr_status"
        :defaulStatusList="filterStatuses"
        @change="onFilterChange"
        emitField="tr_status"
      />
    </div>
    <div class="ProvidersHistoryTable_filters ProvidersHistoryTable_filters--start">
      <Select
        class="ProvidersHistoryTable_filter-provider"
        :items="providerNamesFilter"
        :value="providersHistoryFilters.provider"
        name="provider"
        @change="onFilterChange"
        label="Provider"
        placeholder="Provider Name"
      />
      <TextFieldInput
        class="ProvidersHistoryTable_filter-search mt-[10px] md:mt-0"
        searchInput
        :value="providersHistoryFilters.search"
        name="search"
        placeholder="Transaction ID or Address"
        label="search"
        @input="handleInputSearchQuery(arguments[0])"
        hideDetails
      />
    </div>
    <div class="w-full mb-[10px] bg-white dark:bg-[#213b57] shadow-providers-history-row-root py-[18px] px-[14px] rounded-[4px] hidden md:flex md:items-center md:justify-between">
      <div class="text-[12px] leading-[14px] capitalize w-[70px] text-left text-gray-600 dark:text-white">Provider</div>
      <div class="text-[12px] leading-[14px] capitalize w-[100px] text-center text-gray-600 dark:text-white">Transaction ID</div>
      <div class="text-[12px] leading-[14px] capitalize w-[70px] text-center text-gray-600 dark:text-white">User ID</div>
      <div class="text-[12px] leading-[14px] capitalize w-[70px] text-center text-gray-600 dark:text-white">Creation date</div>
      <div class="text-[12px] leading-[14px] capitalize w-[70px] text-center text-gray-600 dark:text-white">Transaction date</div>
      <div class="text-[12px] leading-[14px] capitalize w-[90px] text-center text-gray-600 dark:text-white overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer">Address</div>
      <div class="text-[12px] leading-[14px] capitalize w-[55px] text-center text-gray-600 dark:text-white lowercase">Type</div>
      <div class="text-[12px] leading-[14px] capitalize w-[55px] text-center text-gray-600 dark:text-white">Currency</div>
      <div class="text-[12px] leading-[14px] capitalize w-[70px] text-center text-gray-600 dark:text-white">Amount</div>
      <div class="text-[12px] leading-[14px] capitalize w-[70px] text-center text-gray-600 dark:text-white">Fee</div>
      <div class="text-[12px] leading-[14px] capitalize text-gray-600 dark:text-white ProvidersHistory-column-status">Status</div>
    </div>
    <div
      v-if="hasOrders"
      class="ProvidersHistoryTable_row-container"
    >
      <HistoryRow
        v-for="(order, index) in orders"
        :key="index"
        :order="order"
      />
    </div>
    <EmptyState v-if="!hasOrders" />
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions, mapGetters } from 'vuex';
import { isEmpty } from 'ramda';
import { PROVIDER_TRANSACTIONS_HISTORY_TYPES_FILTER, PROVIDER_TRANSACTIONS_HISTORY_STATUSES_FILTER } from '@/constants';
import { prepareDateFilterForRequest } from './_helpers/dateTimeFormatter';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import DatePicker from '@/components/_common/FormElements/DatePicker/DatePicker';
import Select from '@/components/_common/FormElements/Select/Select';
import HistoryRow from './HistoryRow/HistoryRow';
import EmptyState from './EmptyState';
import CurrencyFilter from '@/components/_common/ExtraFilters/CurrencyFilter/CurrencyFilter';
import StatusFilter from '@/components/_common/ExtraFilters/StatusFilter/StatusFilter';
import ClickOutside from 'vue-click-outside';

const DATE_FILTER_TYPES = [
  {
    key: 'creation_date',
    value: 'Creation date',
  },
  {
    key: 'transaction_date',
    value: 'Transaction date',
  },
];

export default {
  directives: {
    ClickOutside,
  },
  components: {
    Select,
    DatePicker,
    HistoryRow,
    EmptyState,
    TextFieldInput,    
    CurrencyFilter,
    StatusFilter,
  },
  props: {
    orders: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      filterTypes: PROVIDER_TRANSACTIONS_HISTORY_TYPES_FILTER,
      filterStatuses: PROVIDER_TRANSACTIONS_HISTORY_STATUSES_FILTER,
      filterDateTypes: DATE_FILTER_TYPES,
      searchInputTimer: null,
      datePickerActive: false,
    };
  },
  computed: {
    ...mapState('providers', ['providersHistoryFilters', 'providersHistoryDateFilter']),
    ...mapState('exchange', ['currencies']),
    ...mapGetters('providers', ['userProviders']),
    computeDatePickerValue() {
      const { date_from, date_to } = this.providersHistoryDateFilter;
      if (!!date_from && !!date_to) {
        const dateFrom = moment(date_from).format('YYYY-MM-DD');
        const dateTo = moment(date_to).format('YYYY-MM-DD');
        return [dateFrom, dateTo];
      }
      return [];
    },
    computedDateInputValue() {
      const { date_from, date_to } = this.providersHistoryDateFilter;
      if (!!date_from && !!date_to) {
        const dateFrom = moment(date_from).format('YYYY-MM-DD');
        const dateTo = moment(date_to).format('YYYY-MM-DD');
        return `${dateFrom} - ${dateTo}`;
      }
      return '';
    },
    providerNamesFilter() {
      let results = [{ key: '', value: 'Provider Name' }]
      if (!isEmpty(this.userProviders)) {
        Object.keys(this.userProviders).forEach(item=>{
          results.push({
            key: this.userProviders[item].provider,
            value: this.userProviders[item].provider,
          });
        });
      }
      return results;
    },
    hasOrders() {
      return !isEmpty(this.orders);
    },
    currenciesFilter() {
      if (this.currencies.length) {
        const currenciesArray =
          this.currencies.length &&
          this.currencies.reduce((acc, curr) => {
            acc.push({
              key: curr,
              value: curr,
            });
            return acc;
          }, []);
        return currenciesArray;
      }
      return [];
    },
  },
  methods: {
    ...mapActions('providers', ['populateProvidersHistoryFilter', 'populateProvidersHistoryDateFilter', 'apiGetUserProviders']),
    handleOutsidePickerClick() {
      if (this.datePickerActive) {
        this.datePickerActive = false;
      }
    },
    handleActivateDatePicker() {
      this.datePickerActive = true;
    },
    onFilterChange(payload) {
      this.populateProvidersHistoryFilter(payload);
      this.$emit('updateProvidersHistory');
    },
    handleUpdateDateFilerSelect({ value }) {
      this.populateProvidersHistoryDateFilter({
        field: 'date_type',
        value,
      });
    },
    handleUpdateDateValue(payload) {
      const modifiedPayload = prepareDateFilterForRequest(payload);
      modifiedPayload.forEach(({ field, value }) => {
        this.populateProvidersHistoryDateFilter({ field, value });
      });
    },
    handleClearDateFilter() {
      this.datePickerActive = false;
      this.populateProvidersHistoryDateFilter({ field: 'date_from', value: '' });
      this.populateProvidersHistoryDateFilter({ field: 'date_to', value: '' });
      this.$emit('updateProvidersHistory');
    },
    handleApplyDateFilter() {
      this.datePickerActive = false;
      this.$emit('updateProvidersHistory');
    },
    handleInputSearchQuery(value) {
      this.populateProvidersHistoryFilter({
        field: 'search',
        value,
      });
      if (this.searchInputTimer) clearTimeout(this.searchInputTimer);
      this.searchInputTimer = setTimeout(() => {
        this.$emit('updateProvidersHistory');
      }, 2000);
    },
  },
  mounted() {
    this.apiGetUserProviders();
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

.theme--dark.v-label--is-disabled,
.v-text-field.v-text-field--solo .v-label {
  color: #fff;
}

.ProvidersHistoryTable_row-container {
  width: max-content;
  overflow-x: scroll;
  max-width: 100%;

  @media only screen and (min-width: $tablet-width) {
    width: auto;
    overflow: auto;
  }
}

.ProvidersHistoryTable_filters {
  @media only screen and (min-width: $tablet-width) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    &--start {
      justify-content: flex-start;
    }
  }

  .ProvidersHistoryTable_filter {
    margin: 10px 0;

    @media only screen and (min-width: $tablet-width) {
      margin: 0;
      flex: 0 1 calc(25% - 6px);
    }

    &--relative {
      position: relative;
    }
  }

  .ProvidersHistoryTable_filter-provider {
    flex: 0 1 50%;

    @media screen and (min-width: 768px) {
      margin-right: 10px;
    }
  }

  .ProvidersHistoryTable_filter-search {
    flex: 0 1 50%;

    .v-input.v-input--dense.v-text-field .v-input__control .v-input__slot {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
}

.v-skeleton-loader {
  .v-skeleton-loader__table-cell {
    width: 100%;
    margin-bottom: 8px;

    .v-skeleton-loader__text {
      height: 50px;
    }
  }
}

.ProvidersHistoryTable_filter-skeleton.v-skeleton-loader {
  .v-skeleton-loader__table-cell {
    height: 69px;

    .v-skeleton-loader__text {
      height: 69px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
