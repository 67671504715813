<template>
  <div class="last-of-type:mb-0 mb-[8px] bg-[#213b57] w-max md:w-auto shadow-providers-history-row-root rounded-[4px]">
    <div>
      <div
        class="providers-history-row flex justify-between items-center py-[13px] px-[14px]"
        :class="`ProvidersHistoryRow_row--${ computedHistoryStatus }`"
        @click="toggleDetails"
      >
        <div class="text-[11px] leading-[13px] text-blue-700 dark:text-white ProvidersHistoryRow_col--type w-[70px] overflow-hidden text-ellipsis whitespace-nowrap text-left">
          {{ computedProviderName }}
        </div>
        <div
          class="text-[11px] leading-[13px] text-blue-700 dark:text-white cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap text-center w-[100px]"
          @click="handleCopyTransactionId"
        >
          {{ order.transaction_id }}
        </div>
        <div class="text-[11px] leading-[13px] text-blue-700 dark:text-white cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap text-center w-[80px]">
          {{ order.customer_id }}
        </div>
        <div class="text-[11px] leading-[13px] text-blue-700 dark:text-white w-[70px] text-center">{{ computedHistoryDate(order.creation_date) }}</div>
        <div class="text-[11px] leading-[13px] text-blue-700 dark:text-white w-[70px] text-center">{{ computedHistoryDate(order.transaction_date) }}</div>
        <div class="text-[11px] leading-[13px] text-blue-700 dark:text-white w-[90px] text-center overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer" @click="handleCopyAddress">{{ order.address }}</div>
        <div class="text-[11px] leading-[13px] text-blue-700 dark:text-white w-[55px] text-center lowercase">{{ order.type }}</div>
        <div class="text-[11px] leading-[13px] text-blue-700 dark:text-white w-[55px] text-center">{{ order.currency_name }}</div>
        <div class="text-[11px] leading-[13px] text-blue-700 dark:text-white w-[70px] text-center">{{ computedAmount }}</div>
        <div class="text-[11px] leading-[13px] text-blue-700 dark:text-white w-[70px] text-center">{{ order.fee }}</div>
        <div class="text-[11px] leading-[13px] text-blue-700 dark:text-white ProvidersHistoryRow_col--status w-[50px] text-right">
          <div class="ProvidersHistoryRow_status" :class="`ProvidersHistoryRow_status--${ computedHistoryStatus }`">
            {{ computedHistoryStatus }}
          </div>
        </div>
      </div>
      <div class="ProvidersHistoryRow_details">
        <transition name="fade" mode="out-in">
          <HistoryDetails
            v-if="isDetailsShown"
            :order="order"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { formatHistoryDateTimeToView } from '../_helpers/dateTimeFormatter';
import { formatHistoryOrderStatusToView } from '../_helpers/orderStatusFormatter';
import HistoryDetails from '../HistoryDetails/HistoryDetails';
import { copyToClipboard } from '@/utils/helpers';

export default {
  components: {
    HistoryDetails,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDetailsShown: false,
    };
  },
  computed: {
    computedAmount() {
      if (this.order && this.order.amount) {
        return Math.abs(this.order.amount)
      }
      return '';
    },
    computedProviderName() {
      const { provider_name } = this.order
      return provider_name;
    },
    computedHistoryStatus() {
      return formatHistoryOrderStatusToView(this.order)
    },
    isDetailsAvailable() {
      return this.order.status === 'FAILED' && !!this.order.description
    },
  },
  methods: {
    computedHistoryDate(date) {
      return formatHistoryDateTimeToView(date)
    },
    handleCopyTransactionId() {
      if (this.order.transaction_id) copyToClipboard(this.order.transaction_id)
    },
    handleCopyAddress() {
      if (this.order.address) copyToClipboard(this.order.address)
    },
    toggleDetails() {
      if (this.isDetailsAvailable) {
        this.isDetailsShown = !this.isDetailsShown
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

.ProvidersHistoryRow_status {
  display: inline-block;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #5abc67;
  padding: 4px 8px;
  border-radius: 2px;
  background: rgba(#5abc67, 0.1);
  text-transform: lowercase;
  margin-left: auto;
  vertical-align: top;

  &--pending {
    background: rgba(#2af3f3, 0.1);
    color: #2af3f3;
  }
  &--failed {
    background: rgba(#ff556d, 0.1);
    color: #ff556d;
  }
  &--expired {
    background: rgba(#ff556d, 0.1);
    color: #ff556d;
  }
  &--cancelled {
    opacity: 0.6;
    background: #fff;
    color: #5abc67;
  }
}

.roundArrowBtn {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #2af1f1;
  background: rgba(#2af1f1, 0.21);
  position: relative;

  svg {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
  }
  &--expanded {
    svg {
      transform: translate(50%, -50%) rotate(-180deg);
    }
  }
  &--hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.round-arrow-btn-cancel {
  svg {
    width: 7px;
    height: 5px;
    transform: rotate(180deg);
  }
}
</style>
