<template>
  <div class="EmptyState_root">
    <div class="EmptyState_wrapper">
      <p v-if="emptyType === 'no_orders' || emptyType === 'filters'" class="text-blue-700 dark:text-white">
        You have no transactions history, or there are no records to match your search filters.
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('providers', ['providersHistoryDateFilter']),
    emptyType() {
      const { date_from, date_to } = this.providersHistoryDateFilter;
      const hasFilters = !!date_from || !!date_to;
      if (hasFilters) {
        return 'filters';
      }
      return 'no_orders';
    },
  },
};
</script>

<style lang="scss">
.EmptyState_root {
  .EmptyState_wrapper {
    position: relative;
    min-height: 400px;
    &:before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background-image: url('../../../../../../assets/img/_common/kauri-big-logo.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      height: 250px;
      opacity: 0.2;
    }
    p {
      position: absolute;
      right: 50%;
      top: 40%;
      transform: translate(50%, -50%);
      font-size: 20px;
      font-weight: 400;
    }
  }
}
</style>
