<template>
  <section class="ProvidersHistory_root">
    <ComponentWrapper
      title="History"
    >
      <div slot="tollbar">
        <RoundButton
          class="CreateAndUpdateProvider_backBtn"
          withIcon
          iconType="arrowLeft"
          text="back to dashboard"
          @click="goToDashboard"
        />
      </div>
      <div class="md:p-[20px]" slot="content">
        <AppSection>
          <div slot="content">
            <HistoryTable
              :orders="providersTransactionHistory"
              @updateProvidersHistory="fetchProvidersTransactionsHistory"
            />
            <div
              class="flex justify-between items-center mt-[15px]"
              v-if="providersHistoryPageCount > 0"
            >
              <div class="hidden text-[18px] leading-[21px] text-white md:block">Page {{ providersHistoryFilters.page }} of {{ providersHistoryPageCount }}</div>
              <v-pagination
                class="ProvidersHistory_pagination"
                :value="providersHistoryFilters.page"
                total-visible="5"
                :length="providersHistoryPageCount"
                @input="handlePageChange"
              ></v-pagination>
            </div>
          </div>
        </AppSection>
      </div>
    </ComponentWrapper>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ComponentWrapper from '@/components/_common/ComponentWrapper/ComponentWrapper';
import AppSection from '@/components/_common/AppSection/AppSection';
import HistoryTable from './HistoryTable/HistoryTable';
import RoundButton from "@/components/_common/FormElements/RoundButton/RoundButton";

export default {
  components: {
    AppSection,
    HistoryTable,
    ComponentWrapper,
    RoundButton
  },
  computed: {
    ...mapState('user', ['userSubAccountId']),
    ...mapState('providers', ['providersTransactionHistory', 'providersHistoryPageCount', 'providersHistoryFilters']),
  },
  methods: {
    ...mapActions('providers', ['apiGetUserProvidersTransactionsHistory', 'changeProvidersHistoryPage']),
    goToDashboard() {
      this.$router.push({ path: '/' })
    },
    handlePageChange(page) {
      this.changeProvidersHistoryPage(page);
      this.fetchProvidersTransactionsHistory();
    },
    fetchProvidersTransactionsHistory() {
      if (this.userSubAccountId) this.apiGetUserProvidersTransactionsHistory();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';

.ProvidersHistory_pagination {
  margin-top: 10px;

  .v-pagination {
    li {
      .v-pagination__item {
        background: transparent !important;
        color: #fff;
        box-shadow: none;
        font-size: 18px;
        outline: none;
        min-width: 20px;
        &--active {
          background-color: transparent !important;
          color: #2af3f3;
        }
      }
      .v-pagination__navigation {
        outline: none;
        width: 38px;
        height: 38px;
        background-color: transparent !important;
        background: rgba(42, 243, 243, 0.1) !important;
        box-shadow: none;
        transition: 0.1s all ease;
        &:hover {
          background: #2af3f3 !important;
          box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
          .v-icon {
            color: #2d4d70;
          }
        }
      }
    }
  }
}
</style>
